import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminContext } from '../../AdminContext';
import styles from './CountryProfiles.module.css';
import { Helmet } from 'react-helmet-async';

const COUNTRY_PROFILES_FILE = 'https://server.earf.tv/country-profiles';

const CountryProfileEditorPage = () => {
    const { isAdminAuthenticated } = useContext(AdminContext);
    const navigate = useNavigate();

    const [countryProfiles, setCountryProfiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [editModalVisible, setEditModalVisible] = useState(false);

    useEffect(() => {
        if (!isAdminAuthenticated) {
            navigate('/admin/login');
        } else {
            fetchCountryProfiles();
        }
    }, [isAdminAuthenticated, navigate]);

    const fetchCountryProfiles = async () => {
        setLoading(true);
        try {
            const response = await fetch(COUNTRY_PROFILES_FILE, {
                method: "GET",
                headers: {
                    'x-api-key': process.env.REACT_APP_EARF_API_KEY,
                    'Content-Type': 'application/json',
                    'X-Request-Source': 'Country-Profiles-Admin',

                },
            });
            const data = await response.json();
            setCountryProfiles(data.features || []);
        } catch (err) {
            console.error('Error fetching country profiles:', err);
            setError('Failed to load country profiles.');
        } finally {
            setLoading(false);
        }
    };

    const handleEditProfile = (profile) => {
        setSelectedProfile({ ...profile.properties });
        setEditModalVisible(true);
    };

    const handleUpdateProfile = async () => {
        try {
            const { id, ...updatedProfile } = selectedProfile;

            const response = await fetch(`${COUNTRY_PROFILES_FILE}/admin/${id}/update`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_EARF_API_KEY,
                },
                body: JSON.stringify(updatedProfile),
            });

            if (response.ok) {
                alert('Country profile updated successfully!');
                setEditModalVisible(false);
                fetchCountryProfiles(); // Refresh profiles after update
            } else {
                const errorData = await response.json();
                alert(errorData.error || 'Failed to update country profile.');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            alert('An error occurred. Please try again.');
        }
    };

    const handleDeleteProfile = async (profileId) => {
        try {
            const response = await fetch(`${COUNTRY_PROFILES_FILE}/admin/${profileId}/delete`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_EARF_API_KEY,
                },
            });

            if (response.ok) {
                alert('Country profile deleted successfully!');
                setCountryProfiles((prev) => prev.filter((profile) => profile.properties.id !== profileId));
            } else {
                throw new Error('Failed to delete country profile.');
            }
        } catch (error) {
            console.error('Error deleting profile:', error);
            alert('Error deleting country profile.');
        }
    };

    const getFilteredProfiles = () => {
        if (!searchQuery.trim()) return countryProfiles;

        const lowerQuery = searchQuery.toLowerCase();
        return countryProfiles.filter((profile) =>
            Object.values(profile.properties).some(
                (value) => typeof value === 'string' && value.toLowerCase().includes(lowerQuery)
            )
        );
    };

    const displayValue = (key, value) => {
        if (typeof value === 'boolean') {
            return value ? 'True' : 'False'; // Display booleans as 'True' or 'False'
        } else if (Array.isArray(value)) {
            return value.join(', '); // Convert arrays into comma-separated strings
        } else if (typeof value === 'object' && value !== null) {
            return JSON.stringify(value, null, 2); // Stringify objects for display
        }
        return value || ''; // Default case
    };

    const handleInputChange = (key, value) => {
        if (key === 'emergency_numbers') {
            // Special handling for arrays like `emergency_numbers`
            setSelectedProfile((prev) => ({
                ...prev,
                [key]: value.split(',').map((item) => item.trim()),
            }));
        } else {
            setSelectedProfile((prev) => ({ ...prev, [key]: value }));
        }
    };

    if (loading) {
        return (
            <div className="full-container">
            <div className="spinner-wrapper">
              <div className="spinner-content">
                <img
                  className="image-spinner"
                  src="/Earf-E.png"
                  alt="Loading"
                />
                <div className="browse-loading-text">
                 Loading Country Profiles
                </div>
              </div>
            </div>
          </div>
        );
    }

    if (error) {
        return <div className={styles.error}>{error}</div>;
    }
    const filteredProfiles = getFilteredProfiles().sort((a, b) => {
        const nameA = a.properties.name.toLowerCase();
        const nameB = b.properties.name.toLowerCase();
        return nameA.localeCompare(nameB);
      });
    return (
        <div className={styles['country-profile-editor-container']}>
            <Helmet prioritizeSeoTags>
                <title>Country Profile Editor | Earf Admin</title>
                <meta
                    name="description"
                    content="Login to the Earf.TV admin panel to manage country profiles."
                />
            </Helmet>

            {/* Header */}
            <div className={styles['header-container']}>
  <h1><span className='earf-blue'>EARF</span> Country Profile Editor</h1>
  <div className={styles['search-container']}>
    <input
      type="text"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      placeholder="Search country profiles..."
      className={styles['search-input']}
    />
  </div>
</div>
            {/* Profiles List */}
            <div className={styles['profiles-list']}>
                {filteredProfiles.length > 0 ? (
                    filteredProfiles.map((profile, index) => (
                        <div key={index} className={styles['profile-item']}>
                       <div className={styles['flag-tile']}>
  <img
    src={`https://flagcdn.com/w80/${profile.properties.iso_code.toLowerCase()}.png`}
    alt={`${profile.properties.name} flag`}
    className={styles['flag-image']}
  />
</div>
<h3 className={styles['country-name']}>{profile.properties.name}</h3>
<p className={styles['country-capital']}>Capital: {profile.properties.capital}</p>

                            <div className={styles['profile-actions']}>
                                <button onClick={() => handleEditProfile(profile)} className={styles['edit-button']}>
                                    Edit
                                </button>
                                <button
                                    onClick={() => handleDeleteProfile(profile.properties.id)}
                                    className={styles['delete-button']}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No country profiles found.</p>
                )}
            </div>

            {editModalVisible && selectedProfile && (
                <div className={styles.modal}>
                    <div className={styles['modal-content']}>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleUpdateProfile();
                            }}
                            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                        >
                            <div className={styles['modal-fields']}>
                                {Object.keys(selectedProfile).map((key) => (
                                    <div key={key} className={styles['form-group']}>
                                        <label htmlFor={key}>{key.replace(/_/g, ' ')}</label>
                                        <input
                                            id={key}
                                            type="text"
                                            value={displayValue(key, selectedProfile[key])}
                                            onChange={(e) =>
                                                handleInputChange(key, e.target.value)
                                            }
                                            placeholder={`Enter ${key.replace(/_/g, ' ')}`}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className={styles['modal-buttons']}>
                                <button type="button" className={styles['close-button']} onClick={() => setEditModalVisible(false)}>
                                    Cancel
                                </button>
                                <button type="submit" className={styles['save-button']}>
                                    Save Changes
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CountryProfileEditorPage;
