import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SimilarVideosSidebar.module.css';

const SimilarVideosSidebar = ({ wikidata, tags, currentVideoId }) => {
  const [similarVideos, setSimilarVideos] = useState([]);
  const [headerText, setHeaderText] = useState('');
  const [locationName, setLocationName] = useState('');
  const [isMobile] = useState(window.innerWidth <= 768);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSimilarVideos = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('https://server.earf.tv/similar-videos', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_EARF_API_KEY,
          },
          body: JSON.stringify({
            wikidata,
            tag1: tags[0],
            tag2: tags[1],
            currentVideoId,
          }),
        });
        

        const data = await response.json();
        const results = data.features || [];

        // Smart labeling based on which match was hit
        if (results.length > 0) {
          const sample = results[0].properties;
          if (sample.wikidata === wikidata) {
            setHeaderText('Locations Nearby');
            setLocationName(sample.wikidata);
          } else if (sample.tag1 === tags[0]) {
            setHeaderText(
              <>
                Similar <span style={{ color: 'var(--earf-blue)' }}>{tags[0]}</span>
              </>
            );
          } else if (sample.tag2 === tags[1]) {
            setHeaderText(
              <>
                Similar <span style={{ color: 'var(--earf-blue)' }}>{tags[1]}</span>
              </>
            );
          }
        }

        setSimilarVideos(results);
      } catch (err) {
        console.error('Error fetching similar videos:', err);
      } finally {
        setIsLoading(false);
      }
    };

    if ((wikidata || tags.length > 0) && !isMobile) {
      fetchSimilarVideos();
    }
  }, [wikidata, tags, currentVideoId, isMobile]);

  const handleVideoClick = (videoId, tag2) => {
    if (!videoId || !tag2) return;
    const formattedTag = tag2.toLowerCase().replace(/\s+/g, '-');
    navigate(`/browse/${encodeURIComponent(formattedTag)}/stream/${videoId}`, {
      state: { fromSidebar: true, tag: tag2 },
    });
  };

  if (isMobile) return null;

  return (
    <div className={`${styles['similar-videos-sidebar']} ${isLoading ? '' : styles['fade-in']}`}>
      {isLoading ? (
        <div className={styles.skeletonloader}>
          <div className={styles.skeletonHeader}></div>
          <div className={styles.skeletonItem}></div>
          <div className={styles.skeletonItem}></div>
          <div className={styles.skeletonItem}></div>
        </div>
      ) : (
        <>
          <div className={styles['similar-video-header-container']}>
            <h3>{headerText}</h3>
            {locationName && (
              <h4>
                <span style={{ color: 'var(--earf-blue)' }}>{locationName}</span>
              </h4>
            )}
          </div>

          <div className={styles['sidebar-scrollable-content']}>
            {similarVideos.length > 0 ? (
              similarVideos.map((video) => (
                <div
                  className={styles['similar-video-item']}
                  onClick={() =>
                    handleVideoClick(video.properties.videoId, video.properties.tag2)
                  }
                  key={video.properties.videoId}
                >
                  <img
                    src={`https://img.youtube.com/vi/${video.properties.videoId}/mqdefault.jpg`}
                    alt={video.properties.name}
                    className={styles['similar-video-thumbnail']}
                  />
                  <div className={styles['similar-video-content']}>
                    <p className={styles['name']}>{video.properties.name}</p>
                    <p className={styles['wikidata']}>
                      {video.properties.wikidata || 'No Wikidata available'}
                    </p>
                    <div className={styles['channel-info']}>
                      <img
                        src={video.properties.channelThumbnail}
                        alt={`${video.properties.channelName} thumbnail`}
                        className={styles['channel-thumbnail']}
                      />
                      <span className={styles['channel-name']}>
                        {video.properties.channelName}
                        {video.properties.partner === 'YES' && (
                          <img
                            src="/partner.png"
                            alt="Verified Partner"
                            className={styles['partner-icon']}
                          />
                        )}
                      </span>
                    </div>
                    <div className={styles['sidebar-tags']}>
                      {[video.properties.tag1, video.properties.tag2]
                        .filter(Boolean)
                        .map((tag, index) => (
                          <span key={index} className={styles['sidebar-tag']}>
                            {tag}
                          </span>
                        ))}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className={styles['name']}>No related videos found.</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SimilarVideosSidebar;
