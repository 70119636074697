import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AdminContext } from "../../AdminContext";
import styles from "./StreamChecker.module.css";
import { Helmet } from "react-helmet-async";

const LIVE_CAMERAS_FILE = "https://server.earf.tv/live-cameras";
const BROWSE_CAMERAS_FILE = "https://server.earf.tv/browse-cameras";

const StreamCheckerPage = () => {
  const { isAdminAuthenticated } = useContext(AdminContext);
  const navigate = useNavigate();

  const [liveStreams, setLiveStreams] = useState([]);
  const [browseStreams, setBrowseStreams] = useState([]);
  const [hiddenStreams, setHiddenStreams] = useState([]);
  const [lastFixedStream, setLastFixedStream] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState("all");
  const [selectedStream, setSelectedStream] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedTag, setSelectedTag] = useState("");

  const extractYouTubeId = (url) => {
    const regex =
      /(?:youtube\.com\/.*[?&]v=|youtu\.be\/|youtube\.com\/embed\/|youtube\.com\/shorts\/)([^"&?\/ ]{11})/;
    const match = url.match(regex);
    return match ? match[1] : url;
  };

  const fetchStreams = async (
    setLiveStreams,
    setBrowseStreams,
    setError,
    setLoading
  ) => {
    setLoading(true);
    try {
      const fetchStreamsFromFile = async (file) => {
        const response = await fetch(file, {
          method: "POST",
          headers: {
            "x-api-key": process.env.REACT_APP_EARF_API_KEY,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        return data.features.map((feature) => {
          const channelId = feature.properties.channelId;
          return {
            ...feature.properties,
            tags: feature.properties.tags || [],
            isLive: feature.properties.status === "online",
            channelUrl: channelId
              ? `https://www.youtube.com/channel/${channelId}/streams`
              : null,
            rawData: feature,
          };
        });
      };

      const liveCameras = await fetchStreamsFromFile(LIVE_CAMERAS_FILE);
      const browseCameras = await fetchStreamsFromFile(BROWSE_CAMERAS_FILE);

      setLiveStreams(liveCameras);
      setBrowseStreams(browseCameras);
    } catch (err) {
      console.error("Error fetching streams:", err);
      setError("Failed to load streams");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!isAdminAuthenticated) {
      navigate("/admin/login");
    } else {
      fetchStreams(setLiveStreams, setBrowseStreams, setError, setLoading);
    }
  }, [isAdminAuthenticated, navigate]);

  const handleRestoreLastFixed = () => {
    setHiddenStreams(
      hiddenStreams.filter((stream) => stream !== lastFixedStream)
    );
    setLastFixedStream(null);
  };

  const handleTagClick = (tag) => {
    window.open(`/Browse/${tag}`, "_blank");
  };

  const handleCheckStreamClick = (tags, videoId) => {
    window.open(`/Browse/admin/stream/${videoId}`, "_blank");
  };

  const handleDeleteStream = async (stream) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the stream "${stream.name}"?`
    );

    if (!confirmDelete) return;

    try {
      const isLiveStream = liveStreams.some(
        (s) => s.videoId === stream.videoId
      );
      const apiUrl = isLiveStream
        ? `https://server.earf.tv/live-cameras/admin/${stream.videoId}/delete`
        : `https://server.earf.tv/browse-cameras/admin/${stream.videoId}/delete`;

      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          "x-api-key": process.env.REACT_APP_EARF_API_KEY,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        alert("Stream deleted successfully");
        if (isLiveStream) {
          setLiveStreams(
            liveStreams.filter((s) => s.videoId !== stream.videoId)
          );
        } else {
          setBrowseStreams(
            browseStreams.filter((s) => s.videoId !== stream.videoId)
          );
        }
      } else {
        alert("Error deleting stream");
      }
    } catch (error) {
      console.error("Error deleting stream:", error);
      alert("Error deleting stream");
    }
  };

  const handleToggleStatus = async (stream) => {
    try {
      const apiUrl = `https://server.earf.tv/toggle/toggle-status/${stream.videoId}`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_EARF_API_KEY,
        },
      });

      if (response.ok) {
        const { stream: updatedStream } = await response.json();

        alert(`Stream marked as ${updatedStream.status} successfully`);

        setLiveStreams(
          liveStreams.map((s) =>
            s.videoId === stream.videoId
              ? {
                  ...s,
                  isLive: updatedStream.status === "online",
                  rawData: updatedStream.rawData
                    ? updatedStream.rawData
                    : s.rawData, // Fallback to existing rawData if not provided
                }
              : s
          )
        );
      } else {
        throw new Error("Failed to toggle stream status");
      }
    } catch (error) {
      console.error("Error toggling stream status:", error);
      alert("Error toggling stream status. Please try again.");
    }
  };

  const handleToggleChannelFallback = async (stream) => {
    try {
      const apiUrl = `https://server.earf.tv/toggle/toggle-fallback/${stream.videoId}`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_EARF_API_KEY,
        },
      });

      if (response.ok) {
        const { stream: updatedStream } = await response.json();

        const useChannelForLiveCheck =
          updatedStream.use_channel_for_live_check || false;

        alert(
          `Fallback toggled to "${
            useChannelForLiveCheck ? "Enabled" : "Disabled"
          }"`
        );

        setLiveStreams((prevStreams) =>
          prevStreams.map((s) =>
            s.videoId === stream.videoId
              ? {
                  ...s,
                  rawData: s.rawData ? s.rawData : {}, // Safely handle rawData
                  properties: {
                    ...s.properties,
                    use_channel_for_live_check: useChannelForLiveCheck,
                  },
                }
              : s
          )
        );
      } else {
        throw new Error("Failed to toggle fallback");
      }
    } catch (error) {
      console.error("Error toggling fallback:", error);
      alert("Error toggling fallback. Please try again.");
    }
  };

  const handleEditStream = (stream, isBrowseStream = false) => {
    const fullStream = {
      ...stream,
      ...stream.rawData.properties, // Merge properties from rawData
      latitude: stream.rawData.geometry?.coordinates[1], // Extract latitude
      longitude: stream.rawData.geometry?.coordinates[0], // Extract longitude
      // Explicitly set "status" based on rawData or fallback to isLive flag
      status:
        stream.rawData.properties.status ||
        (stream.isLive ? "online" : "offline"),
      isBrowseStream,
      originalVideoId: stream.videoId,
      tags: stream.rawData.properties.tags || [],

      videoId: extractYouTubeId(stream.videoId),
    };
    setSelectedStream(fullStream);
    setEditModalVisible(true);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setSelectedStream((prev) => ({
      ...prev,
      [name]: name === "videoId" ? extractYouTubeId(value) : value,
    }));
  };

  const handleUpdateStream = async () => {
    try {
      const { originalVideoId, rawData, ...updatedStream } = selectedStream;

      // Determine if it's a live or browse stream based on a property (e.g., `isBrowseStream`)
      const isBrowseStream = selectedStream.isBrowseStream;

      const apiUrl = isBrowseStream
        ? `https://server.earf.tv/browse-cameras/admin/${originalVideoId}/update`
        : `https://server.earf.tv/live-cameras/admin/${originalVideoId}/update`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_EARF_API_KEY,
        },
        body: JSON.stringify(updatedStream),
      });

      if (response.ok) {
        const updatedStreamResponse = await response.json();
        alert(
          `${isBrowseStream ? "Browse" : "Live"} camera updated successfully!`
        );
        setEditModalVisible(false);

        // Re-fetch streams after saving
        await fetchStreams(
          setLiveStreams,
          setBrowseStreams,
          setError,
          setLoading
        );
      } else {
        const errorData = await response.json();
        alert(
          errorData.error ||
            `Failed to update ${isBrowseStream ? "browse" : "live"} camera.`
        );
      }
    } catch (error) {
      console.error("Error updating stream:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const getFilteredStreams = (streams) => {
    let result = streams;

    // Apply status filter (online/offline)
    if (filter === "online") {
      result = result.filter((stream) => stream.isLive);
    } else if (filter === "offline") {
      result = result.filter((stream) => !stream.isLive);
    }

    // Apply tag filter (if a tag is selected)
    if (selectedTag) {
      result = result.filter(
        (stream) => stream.tags && stream.tags.includes(selectedTag)
      );
    }

    // Apply search filter
    if (searchQuery.trim() !== "") {
      const lowerQuery = searchQuery.toLowerCase();
      result = result.filter((stream) =>
        Object.values(stream).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(lowerQuery)
        )
      );
    }

    // Sort streams by tag2 (country), fallback to tag3 if tag2 is missing
    return result.sort((a, b) => {
      const countryA = (a.tag2 || a.tag3 || "").toLowerCase();
      const countryB = (b.tag2 || b.tag3 || "").toLowerCase();
      return countryA.localeCompare(countryB);
    });
  };

  const getVisibleStreams = (streams) => {
    return streams.filter((stream) => !hiddenStreams.includes(stream));
  };

  const filteredLiveStreams = getVisibleStreams(
    getFilteredStreams(liveStreams)
  );
  const filteredBrowseStreams = getVisibleStreams(
    getFilteredStreams(browseStreams)
  );
  const liveOnlineCount = liveStreams.filter((stream) => stream.isLive).length;
  const liveOfflineCount = liveStreams.length - liveOnlineCount;
  const browseOnlineCount = browseStreams.filter(
    (stream) => stream.isLive
  ).length;
  const browseOfflineCount = browseStreams.length - browseOnlineCount;
  if (loading) {
    return (
      <div className="full-container">
        <div className="spinner-wrapper">
          <div className="spinner-content">
            <img className="image-spinner" src="/Earf-E.png" alt="Loading" />
            <div className="browse-loading-text">Loading Streams</div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className={styles["stream-checker-container"]}>
      <Helmet prioritizeSeoTags>
        <title>Feature Editor | Earf Admin</title>
        <meta
          name="description"
          content="Login to the Earf.TV admin panel to manage streams and settings."
        />
        <meta property="og:image" content="https://earf.tv/metaimage.png" />
        <meta name="twitter:image" content="https://earf.tv/metaimage.png" />
      </Helmet>

      {/* Header with Back Button */}
      <div className={styles["header-container"]}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src="/Earf-E.png" alt="Earf Logo" className={styles["logo"]} />
          <h1>EARF Feature Editor</h1>
        </div>
        {/* Search Input */}
        <div className={styles["search-container"]}>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search streams..."
            className={styles["search-input"]}
          />
        </div>
        {/* Back Button */}
        <button className="back-button" onClick={() => navigate(-1)}>
          ← Back
        </button>
      </div>

      {/* Container for restore and download buttons */}
      <div className={styles["buttons-container"]}>
        {/* Button to restore last fixed stream (center-aligned) */}
        {lastFixedStream && (
          <div className={styles["restore-container"]}>
            <button
              className={styles["restore-button"]}
              onClick={handleRestoreLastFixed}
            >
              Restore Last Fixed Stream
            </button>
          </div>
        )}
      </div>

      <div className={styles["stream-columns-wrapper"]}>
        <div className={styles["stream-header"]}>
          Live Streams ({liveOnlineCount} Online / {liveOfflineCount} Offline)
        </div>
        <div className={styles["stream-header"]}>
          Browse Streams ({browseOnlineCount} Online / {browseOfflineCount}{" "}
          Offline)
        </div>
      </div>
      {/* Dropdowns for filtering */}
      <div className={styles["filter-container"]}>
        {/* Status Filter (All, Online, Offline) */}
        <select
          id="status-filter"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className={styles["filter-select"]}
        >
          <option value="all">All Streams</option>
          <option value="online">Online Only</option>
          <option value="offline">Offline Only</option>
        </select>
      </div>

      {/* Scrollable Columns */}
      <div className={styles["stream-columns"]}>


        {/* Live Streams Column */}
        <div className={styles["stream-column"]}>
          {filteredLiveStreams.length > 0 ? (
            filteredLiveStreams.map((stream, index) => (
              <div
                key={index}
                className={`${styles["stream-item"]} ${
                  stream.isLive ? styles["online"] : styles["offline"]
                }`}
              >
                {" "}
                {/* Delete Button - top left */}
                <button
                  className={styles["delete-button"]}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteStream(stream);
                  }}
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
                {/* Left: Thumbnail */}
                <div className={styles["stream-thumbnail-container"]}>
                  <img
                    src={`https://i.ytimg.com/vi/${stream.videoId}/hqdefault.jpg`}
                    alt={stream.name}
                    className={styles["stream-thumbnail"]}
                  />
                </div>
                {/* Middle: Info */}
                <div className={styles["stream-info"]}>
                  <h3>{stream.name}</h3>
                  {stream.channelName && (
                    <div className={styles["channel-info"]}>
                      <img
                        src={`${stream.channelThumbnail}`}
                        alt="Channel"
                        className={styles["channel-thumbnail"]}
                      />
                      <span className={styles["channel-name"]}>
                        {stream.channelName}
                        {stream.partner === "YES" && (
                          <img
                            src="/partner.png"
                            alt="Partnered"
                            className={styles["partner-icon"]}
                          />
                        )}
                      </span>
                    </div>
                  )}
                  <p>{stream.isLive ? "Online" : "Offline"}</p>
                  {stream.wikidata && <p>{stream.wikidata}</p>}
                  {stream.website && (
                    <p>
                      <a
                        href={
                          stream.website.startsWith("http")
                            ? stream.website
                            : `https://${stream.website}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {stream.website}
                      </a>
                    </p>
                  )}
                  {stream.tags?.length > 0 && (
                    <div className={styles["stream-tags"]}>
                      {stream.tags.map((tag, idx) => (
                        <span
                          key={idx}
                          className={styles["tag"]}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTagClick(tag);
                          }}
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
                {/* Right: Actions */}
                <div className={styles["stream-buttons"]}>
                  <button
                    className={styles["toggle-status-button"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleStatus(stream);
                    }}
                  >
                    {stream.isLive ? "Set Offline" : "Set Online"}
                  </button>

                  <button
                    className={`${styles.fallbackButton} ${
                      stream.rawData.properties.use_channel_for_live_check
                        ? styles.fallbackButtonGreen
                        : styles.fallbackButtonRed
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleChannelFallback(stream);
                    }}
                  >
                    {stream.rawData.properties.use_channel_for_live_check
                      ? "Disable Fallback"
                      : "Enable Fallback"}
                  </button>

                  <button
                    className={styles["check-stream-button"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCheckStreamClick(stream.tags[0], stream.videoId);
                    }}
                  >
                    Check Stream
                  </button>

                  <button
                    className={styles["youtube-search-button"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      const query = encodeURIComponent(stream.title);
                      window.open(
                        `https://www.youtube.com/results?search_query=${query}&sp=EgJAAQAwAQ%253D%253D`,
                        "_blank"
                      );
                    }}
                  >
                    <img
                      src="/Stream-Assets/youtube.png"
                      alt="YouTube"
                      className={styles["youtube-icon"]}
                    />
                  </button>

                  <button
                    onClick={() => handleEditStream(stream)}
                    className={styles["edit-button"]}
                  >
                    Edit
                  </button>

                  <a
                    href={stream.channelUrl || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles["watch-button"]} ${
                      stream.isLive
                        ? styles["online-button"]
                        : styles["offline-button"]
                    }`}
                  >
                    Channel
                  </a>
                </div>
              </div>
            ))
          ) : (
            <p>No {filter} live streams found.</p>
          )}
        </div>

        {/* Browse Streams Column */}
        <div className={`${styles["stream-column"]} ${styles["hide-on-mobile"]}`}>
          {filteredBrowseStreams.length > 0 ? (
            filteredBrowseStreams.map((stream, index) => (
              <div
                key={index}
                className={`${styles["stream-item"]} ${
                  stream.isLive ? styles["online"] : styles["offline"]
                }`}
              >
                {/* Delete Button - top left */}
                <button
                  className={styles["delete-button"]}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteStream(stream);
                  }}
                >
                  <i className="fas fa-trash-alt"></i>
                </button>

                {/* Left: Thumbnail */}
                <div className={styles["stream-thumbnail-container"]}>
                  <img
                    src={`https://i.ytimg.com/vi/${stream.videoId}/hqdefault.jpg`}
                    alt={stream.name}
                    className={styles["stream-thumbnail"]}
                  />
                </div>

                {/* Middle: Info */}
                <div className={styles["stream-info"]}>
                  <h3 className={styles["stream-title"]}>{stream.name}</h3>

                  {stream.channelName && (
                    <div className={styles["channel-info"]}>
                      <img
                        src={`${stream.channelThumbnail}`}
                        alt="Channel"
                        className={styles["channel-thumbnail"]}
                      />
                      <span className={styles["channel-name"]}>
                        {stream.channelName}
                        {stream.partner === "YES" && (
                          <img
                            src="/partner.png"
                            alt="Partnered"
                            className={styles["partner-icon"]}
                          />
                        )}
                      </span>
                    </div>
                  )}

                  <p>{stream.isLive ? "Online" : "Offline"}</p>
                  {stream.wikidata && <p>{stream.wikidata}</p>}
                  {stream.website && (
                    <p>
                      <a
                        href={
                          stream.website.startsWith("http")
                            ? stream.website
                            : `https://${stream.website}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {stream.website}
                      </a>
                    </p>
                  )}
                  {stream.tags?.length > 0 && (
                    <div className={styles["stream-tags"]}>
                      {stream.tags.map((tag, idx) => (
                        <span
                          key={idx}
                          className={styles["tag"]}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTagClick(tag);
                          }}
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  )}
                </div>

                {/* Right: Actions */}
                <div className={styles["stream-buttons"]}>
                  <button
                    className={styles["toggle-status-button"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleStatus(stream);
                    }}
                  >
                    {stream.isLive ? "Set Offline" : "Set Online"}
                  </button>

                  <button
                    className={`${styles.fallbackButton} ${
                      stream.rawData.properties.use_channel_for_live_check
                        ? styles.fallbackButtonGreen
                        : styles.fallbackButtonRed
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleChannelFallback(stream);
                    }}
                  >
                    {stream.rawData.properties.use_channel_for_live_check
                      ? "Disable Fallback"
                      : "Enable Fallback"}
                  </button>

                  <button
                    className={styles["check-stream-button"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCheckStreamClick(stream.tags[0], stream.videoId);
                    }}
                  >
                    Check Stream
                  </button>

                  <button
                    className={styles["youtube-search-button"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      const query = encodeURIComponent(stream.title);
                      window.open(
                        `https://www.youtube.com/results?search_query=${query}&sp=EgJAAQAwAQ%253D%253D`,
                        "_blank"
                      );
                    }}
                  >
                    <img
                      src="/Stream-Assets/youtube.png"
                      alt="YouTube"
                      className={styles["youtube-icon"]}
                    />
                  </button>

                  <button
                    onClick={() => handleEditStream(stream)}
                    className={styles["edit-button"]}
                  >
                    Edit
                  </button>

                  <a
                    href={stream.channelUrl || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles["watch-button"]} ${
                      stream.isLive
                        ? styles["online-button"]
                        : styles["offline-button"]
                    }`}
                  >
                    Channel
                  </a>
                </div>
              </div>
            ))
          ) : (
            <p>No {filter} browse streams found.</p>
          )}
        </div>



      </div>

      {editModalVisible && selectedStream && (
        <div className={styles["modal"]}>
          <div className={styles["modal-content"]}>
            <h2>Edit Stream</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdateStream(); // Update the stream when the form is submitted
              }}
              className={styles["edit-form"]}
            >
              <div className={styles["form-grid"]}>
                {Object.keys(selectedStream)
                  .filter((key) => key !== "rawData" && key !== "isLive") // Exclude rawData and isLive from rendering
                  .map((key) => {
                    if (key === "partner") {
                      return (
                        <div key={key} className={styles["form-group"]}>
                          <label style={{ fontWeight: "bold" }}>
                            {key.toUpperCase()}
                          </label>
                          <select
                            value={selectedStream.partner || ""}
                            onChange={(e) =>
                              setSelectedStream({
                                ...selectedStream,
                                partner: e.target.value,
                              })
                            }
                          >
                            <option value="">NULL</option>
                            <option value="YES">YES</option>
                            <option value="NO">NO</option>
                          </select>
                        </div>
                      );
                    } else if (key === "status") {
                      // Render the status toggle for the "status" field
                      return (
                        <div key={key} className={styles["form-group"]}>
                          <label style={{ fontWeight: "bold" }}>
                            {key.toUpperCase()}
                          </label>
                          <button
                            type="button"
                            className={`${styles["status-toggle"]} ${
                              selectedStream[key] === "online"
                                ? styles["status-online"]
                                : styles["status-offline"]
                            }`}
                            onClick={() =>
                              setSelectedStream({
                                ...selectedStream,
                                [key]:
                                  selectedStream[key] === "online"
                                    ? "offline"
                                    : "online",
                              })
                            }
                          >
                            {selectedStream[key] === "online"
                              ? "Set Offline"
                              : "Set Online"}
                          </button>
                        </div>
                      );
                    } else {
                      return (
                        <div key={key} className={styles["form-group"]}>
                          <label style={{ fontWeight: "bold" }}>
                            {key.toUpperCase()}
                          </label>
                          <input
                            type="text"
                            value={selectedStream[key] || ""}
                            onChange={(e) => {
                              const value = e.target.value;
                              setSelectedStream({
                                ...selectedStream,
                                [key]:
                                  key === "videoId"
                                    ? extractYouTubeId(value)
                                    : value,
                              });
                            }}
                            placeholder={`Enter ${key}`}
                          />
                        </div>
                      );
                    }
                  })}
              </div>
              <div className={styles["modal-buttons"]}>
                <button
                  type="button"
                  className={styles["close-button"]}
                  onClick={() => setEditModalVisible(false)}
                >
                  Close
                </button>
                <button type="submit" className={styles["save-button"]}>
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default StreamCheckerPage;
